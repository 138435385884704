<template>
  <div class="register">
    <el-row
      class="content"
      :gutter="24">
      <el-col
        :xs="0"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="register-left">
        <TransitionGroup
          appear
          tag="div"
          enter-active-class="animate__animated animate__bounceInLeft">
          <div
            class="left-bg"
            key="1"></div>
        </TransitionGroup>
      </el-col>
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="register-right">
        <Transition
          appear
          enter-active-class="animate__animated animate__bounceInRight">
          <el-form
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            class="register-form"
            auto-complete="on"
            label-position="top">
            <h2 class="title"><a :href="homeUrl">BrickSDK</a></h2>
            <h3>账号注册</h3>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="邮箱"
                  prop="email">
                  <el-input v-model="registerForm.email"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="密码"
                  prop="passwd">
                  <el-input
                    v-model.trim="registerForm.passwd"
                    type="password"
                    show-password></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="企业全称/工作室名称">
                  <el-input v-model="registerForm.company"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业地址/工作室地址">
                  <el-input v-model="registerForm.company_addr"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="联系人">
                  <el-input v-model="registerForm.contacts"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码">
                  <el-input v-model="registerForm.mobile"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="18">
                <el-form-item
                  label="验证码"
                  prop="code">
                  <el-input v-model="registerForm.code"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="6"
                style="margin-top: 40px">
                <el-button
                  :class="[
                    'btn',
                    sendDisabled || !resendShow ? 'btns-info' : 'btns-default',
                  ]"
                  :disabled="sendDisabled"
                  @click="handleResend">
                  <template v-if="resendShow">发送</template>
                  <template v-else>{{ times }}s</template>
                </el-button>
              </el-col>
            </el-row>
            <el-button
              class="btn zc-btn btns-default"
              @click="handelRegister"
              >立即注册</el-button
            >
            <div class="text">
              已有账号？<router-link to="/login">立即登录</router-link>
            </div>
          </el-form>
        </Transition>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'animate.css';
import { isEmail, isPassword } from '@/assets/js/validate';
import { sendCode, sendRegister } from '@/api/login';

let timer = null;
export default {
  name: 'register',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        this.sendDisabled = true;
        callback(new Error('请输入邮箱'));
      } else if (!isEmail(value)) {
        this.sendDisabled = true;
        callback(new Error('请输入正确格式的邮箱'));
      } else {
        this.sendDisabled = false;
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'));
      } else if (!isPassword(value)) {
        callback(new Error('请输入6-15位由字母或数字组成的密码'));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        email: '',
        passwd: '',
        company: '',
        company_addr: '',
        contacts: '',
        mobile: '',
        code: '',
      },
      registerRules: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        passwd: [
          { required: true, validator: validatePassword, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      resendShow: true,
      sendDisabled: false,
      times: 60,
      homeUrl: '',
    };
  },
  methods: {
    handleResend() {
      this.$refs.registerForm.validateField('email', (err) => {
        if (!err) {
          this.countDown();
          sendCode({
            email: this.registerForm.email,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
            }
          });
        }
      });
    },
    countDown() {
      let timecount = 60;
      if (!timer) {
        this.times = timecount;
        this.resendShow = false;
        this.sendDisabled = true;
        timer = setInterval(() => {
          if (this.times > 0 && this.times <= timecount) {
            this.times--;
          } else {
            this.resendShow = true;
            this.sendDisabled = false;
            clearInterval(timer);
            timer = null;
          }
        }, 1000);
      }
    },
    handelRegister() {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          sendRegister(this.registerForm).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.$router.push('/login');
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.homeUrl = process.env.VUE_APP_BASE_URL;
  },
};
</script>

<style>
.register-form .el-form-item__label {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.register {
  width: 100%;
  height: 100vh;

  .content {
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .register-left {
    padding-left: 0 !important;
    border-right: 1px solid #f3d7ff;
    height: 100%;
    div {
      height: 100%;
    }
  }
  .left-bg {
    background: url(~@/assets/images/services-left.jpg) no-repeat left center;
    background-size: 100%;
    width: 100%;
    height: 100%;
  }
  .register-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .register-form {
    width: 70%;
    h3 {
      margin: 80px 0 20px 0;
    }
    .title a {
      color: #c03afe;
      margin-bottom: 40px;
    }
    .btn {
      background-color: #5b03e4;
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
    }
    .btns-default {
      background-color: #5b03e4;
      color: #fff;
    }
    .btns-info {
      color: #909399;
      background: #f4f4f5;
      border: 1px solid #dcdfe6;
    }
    .btn:hover {
      opacity: 0.8;
    }
    .zc-btn {
      margin: 30px 0;
    }
    .text {
      color: #606266;
      text-align: right;
      a {
        color: #5b03e4;
        cursor: pointer;
      }
    }
  }
}
</style>
